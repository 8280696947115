import React from "react";
import "./PortfolioComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import portfolioImg from "../../Images/portfolio-image.png"

const PortfolioComponent = () => {
  return (
    <div className="troo-da-services-section" id="troo-da-services-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                Portfolio
              </div>
              <div className="title">
                <h2>
                  <span>
                    Look how wonderful work <br />
                    we have
                  </span>
                  done !
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ser-tt">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="portfolio-img">
              <img src={portfolioImg} alt="portfolioImg" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="portfolio-text">
              <h3>Information Technology</h3>
              <p>
                Lorem ipsum is simply free text used qui quiqui by copytyping
                refreshing. Neque porro est qui dolorem and ipsum quia quaed
                inventore veritatis et quasi architecto & beatae vitae dicta
                sunt explicabo. Aelltes port lacus quis & enim var sed efficitur
                turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the ndustry standard dummy
              </p>
              <ul>
                <li>
                  <strong>Client:</strong> <span>Wallchart e-commerce</span>
                </li>
                <li>
                  <strong>Year:</strong> <span>22 August, 2022</span>
                </li>
                <li>
                  <strong>Category:</strong> <span>Business Planning</span>
                </li>
                <li>
                  <strong>Country:</strong> <span>Tirana, Albania</span>
                </li>
                <li>
                  <strong>Website:</strong> <span>www.wallchart.com</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="service-deil-text">
              <h2>Detail about project</h2>
              <p>
                Lorem ipsum is simply free text used by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis
                et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                port lacus quis enim var sed efficitur turpis gilla sed sit amet
                finibus eros. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the ndustry
                standard dummy text ever since the 1500s. Lorem ipsum is simply
                free text used by copytyping refreshing. Neque porro est qui
                dolorem ipsum quia quaed inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim
                var sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the ndustry standard dummy text
                ever since the 1500s.
              </p>
              <p>
                Lorem ipsum is simply free text used by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis
                et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                port lacus quis enim var sed efficitur turpis gilla sed sit amet
                finibus eros. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the ndustry
                standard dummy text ever since the 1500s. Lorem ipsum is simply
                free text used by copytyping refreshing. Neque porro est qui
                dolorem ipsum quia quaed inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim
                var sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the ndustry standard dummy text
                ever since the 1500s m ipsum is simply free text used by
                copytyping refreshing. Neque porro est qui dolorem ipsum quia
                quaed inventore veritatis et quasi architecto beatae
              </p>
              <h3>Our solutation </h3>
              <p>
                Lorem ipsum is simply free text used by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis
                et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                port lacus quis enim var sed efficitur turpis gilla sed sit amet
                finibus eros. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the ndustry
                standard dummy text ever since the 1500s. Lorem ipsum is simply
                free text used by copytyping refreshing. Neque porro est qui
                dolorem ipsum quia quaed inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim
                var sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the ndustry standard dummy text
                ever since the 1500s.
              </p>
              <p>
                Lorem ipsum is simply free text used by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis
                et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                port lacus quis enim var sed efficitur turpis gilla sed sit amet
                finibus eros. Lorem Ipsum is simply dummy text and of the
                printing and typesetting industry. Lorem Ipsum has been the
                ndustry.
              </p>
              <p>
                Lorem ipsum is simply free text used by copytyping refreshing.
                Neque porro est qui dolorem ipsum quia quaed inventore veritatis
                et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                port lacus quis enim var sed efficitur turpis gilla sed sit amet
                finibus eros. Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the ndustry
                standard dummy text ever since the 1500s. Lorem ipsum is simply
                free text used by copytyping refreshing. Neque porro est qui
                dolorem ipsum quia quaed inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim
                var sed efficitur turpis gilla sed sit amet finibus eros. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the ndustry standard dummy text
                ever since the 1500s m ipsum is simply free text used by
                copytyping refreshing. Neque porro est qui dolorem ipsum quia
                quaed inventore veritatis et quasi architecto beatae
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioComponent;
