import React, { useState } from 'react'
import "./QuoteComponent.css"

const QuoteComponent = () => {
  const [text, setText] = useState('');

  function handleChange(event) {
    setText(event.target.value);
  }
  return (
    <div className="troo-da-request-form-section" id="troo-da-request-form-section">
      <div className="container">
       <div className="row">
        <div className="form-section">
          <h3>Request a quote</h3>
          <form className="row">
            <div className="col-md-6">
              <input type="FullName" className="form-control" id="inputFullName" placeholder="Full Name"/>
            </div>
            <div className="col-md-6">
              <input type="EmailAddress" className="form-control" id="inputEmailAddress" placeholder="Email Address"/>
            </div>
            <div className="col-md-6">
              <input type="Phone" className="form-control" id="inputPhone" placeholder="Phone No"/>
            </div>
            <div className="col-md-6">
              <select id="inputcountry" className="form-select">
                <option selected>Country</option>
                <option>...</option>
              </select>
            </div>
            <div className="col-md-6">
              <select id="inputproject" className="form-select">
                <option selected>Project tupe</option>
                <option>...</option>
              </select>
            </div>
            <div className="col-md-6">
              <input type="Estimatebudget" className="form-control" id="inputPEstimatebudget" placeholder="Estimate budget"/>
            </div>
           
            <div className="col-md-6">
              <input type="EmailMaxomamproject" className="form-control" id="inputMaxomamproject" placeholder="Maxomam time for project"/>
            </div>
            <div className="col-md-6">
              <input type="EmailCompanyname" className="form-control" id="inputCompanyname" placeholder="Company name"/>
            </div>
            <div className="col-md-12">
              <input type="Emailrequired-skill" className="form-control" id="inputrequiredskill" placeholder="Required skill"/>
            </div>
            <div className="col-md-12">
              <div className="upload-file">
                <label>Drop project file here or click below button</label>
                 <span>Upload File</span>
                <input type="file" id="myFile" name="filename" placeholder="Upload File"/>
         
              </div>
            </div>
            <div className="col-md-12">
              <textarea value={text} onChange={handleChange} placeholder="Write a message....." />
            </div>
           <div className="col-12">
              <button type="submit" className="btn btn-primary">Submit form</button>
            </div>
          </form>
        </div>
       </div>
    </div>
    </div>
  )
}

export default QuoteComponent