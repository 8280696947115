import React from "react";
import tri_icon from "../../Images/tri.png";
import sub_text_icon from "../../Images/gre-aa.png";
import org_tr from "../../Images/org-tr.png";
import cross from "../../Images/crose.png";
import { Link } from "react-router-dom";
import faq_img from "../../Images/faq.png";
import "./FaqComponent.css";
import Accordion from "react-bootstrap/Accordion";

const FaqComponent = () => {
  return (
    <div className="troo-da-faqs-section" id="troo-da-faqs-section">
      <div className="pot">
        <img src={tri_icon} alt="tri_icon" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                FAQs
              </div>
              <div className="title">
                <h2>
                  <span>Have you any </span> question?
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="case-text">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="case-btn">
              <div className="get-col">
                <Link to="/Home/FAQ">Contact us</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Why do I need a business consulting service?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How much does it cost to hire a business consultant?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What industries or businesses do you specialize in?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How do you price your services?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the terms of working with a business consultant?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-md-6">
            <div className="zoom-in-zoom-out">
              <img src={cross} alt="cross_icon" />
            </div>
            <div className="vert-move">
              <img src={faq_img} alt="faq_img" />
            </div>
          </div>
        </div>
      </div>
      <div className="vid-fig">
        <img src={org_tr} alt="org-tr" />
      </div>
    </div>
  );
};

export default FaqComponent;
