
import "./BlogComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import { Link, createSearchParams } from "react-router-dom";
import { blogData } from "./BlogData";

const BlogComponent = () => {
  
  return (
    <div className="troo-da-blogs-section" id="troo-da-blogs-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text" />
                Our Blog Post
              </div>
              <div className="title">
                <h2>
                  <span>
                    View the Latest News in <br />
                  </span>
                  Consultancy.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {blogData.slice(0,3).map((data, i) => (
            <div className="col-md-4" key={i}>
              <Link to={`/Home/Blog_Details?${createSearchParams({id : data.id})}`}>
              <div className="blog-content">
                <div className="bl-img">
                  <img src={data.img} alt="blog_1" />
                  <div className="date-ss">{data.date}</div>
                </div>
                <div className="blog-text">
                  <h3>{data.title}</h3>
                  <div className="blog-client">
                    <div className="blog-img">
                      <img src={data.avtar} alt="blog_person_1" />
                      <strong> {data.desig}</strong>
                    </div>
                    <div className="blog-con">
                      <span>{data.work}</span>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          ))}
          <div className="col-md-12">
            <div className="case-btn">
              <div className="get-col">
                <Link to = "/Home/Blogs">Read all blogs</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;
