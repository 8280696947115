import React from "react";
import "./ReviewPage.css";
import taem1 from "../../Images/business-team.png"
import taem2 from "../../Images/hand-man.png"
import taem3 from "../../Images/computer-work-hand.png"
import sub_text_icon from "../../Images/gre-aa.png";
import { FaStar } from 'react-icons/fa';


const Reviewpage = () => {
  return (
    <>
      <div className="troo-da-services-section" id="troo-da-services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sub-title">
                <div className="text-sub">
                  <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                  Clients Review
                </div>
                <div className="title">
                  <h2>
                    <span>
                      We have many reviews from <br />
                      our satisfied
                    </span>
                    clients.
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ser-tt">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial-sub" id="testimonial-sub">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                    <img src={taem1} alt="taem1" />
                  </div>
                  <div className="client-con">
                    <strong>Jessca Arow</strong>
                    <span>abc company, CEO</span>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                    <img src={taem2} alt="taem2" />
                  </div>
                  <div className="client-con">
                    <strong>Robert James</strong>
                    <span>xyz, Finance Manager</span>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                    <img src={taem3} alt="taem3" />
                  </div>
                  <div className="client-con">
                    <strong>Kevin Martin</strong>
                    <span>abc Company, MD</span>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                  <img src={taem3} alt="taem3" />
                  </div>
                  <div className="client-con">
                    <strong>Jessca Arow</strong>
                    <span>abc company, CEO</span>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                  <img src={taem2} alt="taem2" />
                  </div>
                  <div className="client-con">
                    <strong>Robert James</strong>
                    <span>xyz, Finance Manager</span>
                   <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                  <img src={taem1} alt="taem1" />
                  </div>
                  <div className="client-con">
                    <strong>Kevin Martin</strong>
                    <span>abc Company, MD</span>
                   <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                  <img src={taem1} alt="taem1" />
                  </div>
                  <div className="client-con">
                    <strong>Jessca Arow</strong>
                    <span>abc company, CEO</span>
                   <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                  <img src={taem2} alt="taem2" />
                  </div>
                  <div className="client-con">
                    <strong>Robert James</strong>
                    <span>xyz, Finance Manager</span>
                   <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="test-client">
                <div className="client-deatils">
                  <div className="client-img">
                  <img src={taem3} alt="taem3" />
                  </div>
                  <div className="client-con">
                    <strong>Kevin Martin</strong>
                    <span>abc Company, MD</span>
                   <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                    <FaStar className="fa_star"/>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply and dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been and the industry's
                  standard dummy Lorem and Ipsum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviewpage;
