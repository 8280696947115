import React from "react";
import "./ServiceDetailsComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import service_details_1 from "../../Images/service-deatis-1.png";
import service_details_2 from "../../Images/service-deatis-2.png";
import arrtowicon from "../../Images/Icon.png";
import deail_cr from "../../Images/deail-cr.png";
import service_benifit_1 from "../../Images/service-benefits-1.png";
import service_benifit_2 from "../../Images/service-benefits-2.png";

const ServiceDetailsComponent = () => {
  return (
    <div className="consulting-services-pages-details">
      <div className="troo-da-services-section" id="troo-da-services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sub-title">
                <div className="text-sub">
                  <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                  Our Services
                </div>
                <div className="title">
                  <h2>
                    <span>
                      Our clients at the front of their field by advancing an
                    </span>
                    agenda.
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ser-tt">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="service-img">
                <img src={service_details_1} alt="service_details_1" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img">
                <img src={service_details_2} alt="service_details_2" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="service-deil-text">
                <h2>Business Planning</h2>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s. Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s.
                </p>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s. Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s m ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae
                </p>
                <h3>We follow simple steps for your business syccess</h3>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s. Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s.
                </p>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text and of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry.
                </p>
                <p>
                  Lorem ipsum is simply free text used by copytyping refreshing.
                  Neque porro est qui dolorem ipsum quia quaed inventore
                  veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s. Lorem ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Aelltes port lacus quis enim var sed efficitur
                  turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply
                  dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the ndustry standard dummy text ever since the
                  1500s m ipsum is simply free text used by copytyping
                  refreshing. Neque porro est qui dolorem ipsum quia quaed
                  inventore veritatis et quasi architecto beatae
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="troo-da-service-benefits-section"
        id="troo-da-service-benefits-section"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-text">
                <div className="sub-title">
                  <div className="text-sub">
                    <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                    Why Choose us
                  </div>
                  <div className="title">
                    <h2>
                      <span>Service </span>Benefits
                    </h2>
                  </div>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer too.
                </p>
                <ul>
                  <li>
                    <img src={arrtowicon} alt="arrtowicon" />{" "}
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy.
                    </span>
                  </li>
                  <li>
                    <img src={arrtowicon} alt="arrtowicon" />{" "}
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy.
                    </span>
                  </li>
                  <li>
                    <img src={arrtowicon} alt="arrtowicon" />{" "}
                    <span>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box">
                <img src={deail_cr} alt="deail_cr" />
              </div>
              <div className="left-ss-im vid-fig">
                <img src={service_benifit_1} alt="service_benifit_1" />
              </div>
              <div className="right-ss-im">
                <img src={service_benifit_2} alt="service_benifit_2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailsComponent;
