import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import useDocumentTitle from '../../PageTitle';
import ServiceDetailsComponent from '../../Components/ServiceComponent/ServiceDetailsComponent';
const ServiceDetails = () => {
  useDocumentTitle("TROO Consulting Service Offer")
  return (
    <div>
       <Header />
       <HeroCompoentGlobal/>
       <ServiceDetailsComponent/>
       <Footer/>
    </div>
  )
}

export default ServiceDetails