import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { BiPhone } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { TbBrandInstagram } from "react-icons/tb";
import { TiSocialTwitter,  TiSocialFacebook,  TiSocialLinkedin} from "react-icons/ti";
import weblogo from "../../Images/Logo.png";
import { MenuData } from "./MenuData";
import Navmenu from "./Navmenu";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const containerRef = useRef()

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 20 || document.documentElement.scrollTop > 20) {
      navbar_ref.current.style.top = "0";
    } else {
      navbar_ref.current.style.top = "-150px";
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.scrollTo(0, 0)
  }, []);

  const handleScrollBar = () => {
    if (!click) {
      document.body.classList.add("stopScroll");
    } else {
      document.body.classList.remove("stopScroll");
    }
    setClick(!click);
  };

  return (
    <div className="header" >
      <section className="show_nav" ref={navbar_ref}>
        <div className="container">
          <Navbar expand="lg" collapseOnSelect={false}>
            <Container ref={containerRef}>
              <Navbar.Brand>
                <Link className="navbar-brand" to="/">
                  <img src={weblogo} alt="weblogo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={handleScrollBar}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                  {MenuData.slice(0, 6).map((item, index) => {
                    return <Navmenu item={item} key={index} />;
                  })}
                </ul>
                <div className="get-col">
                  {MenuData.slice(-1).map((e, i) => {
                    return (
                      <Link key={i} to={e.path}>
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>

      <section id="troo-da-header" className="troo-da-header">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="addres-deatls">
                <div className="add-bx">
                  <Link to="">
                    <BiPhone className="s-icon" />
                    <span> +44 123 456 7890</span>
                  </Link>
                </div>
                <div className="add-bx">
                  <Link to="">
                    <FiMail className="s-icon" />
                    <span>example@email.com</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="socail-con">
                <div className="socal-icon">
                  <ul>
                    <li>
                      <Link to="">
                        <TiSocialFacebook className="s_icon" />
                        <span>Facebook</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <TbBrandInstagram className="s_icon" />
                        <span>Instagram</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <TiSocialLinkedin className="s_icon" />
                        <span>Linkedin</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <TiSocialTwitter className="s_icon" />
                        <span>Twitter</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Navbar expand="lg" className="navbar_cont">
            <Container>
              <Navbar.Brand>
                <Link className="navbar-brand" to="/">
                  <img src={weblogo} alt="weblogo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={handleScrollBar}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                  {MenuData.slice(0, 6).map((item, index) => {
                    return <Navmenu item={item} key={index} />;
                  })}
                </ul>
                <div className="get-col">
                  {MenuData.slice(-1).map((e, i) => {
                    return (
                      <Link key={i} to={e.path}>
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default Header;
