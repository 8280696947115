import React from "react";
import "./ContactComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import contact_icon from "../../Images/contact-ion.png";

const ContactComponent = () => {
  const googleMap =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8850363027605!2d72.49413012967058!3d23.027992973780535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674455711083!5m2!1sen!2sin";
  return (
    <>
      <div className="troo-da-form-section form_section" id="troo-da-form-section">
        <div className="zoom-in-zoom-out">
          <img src={contact_icon} alt="contact_icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 titlecont">
              <div className="sub-title">
                <div className="text-sub">
                  <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                  Contact with Us
                </div>
                <div className="title">
                  <h2>
                    <span> We would love to hear from </span> you.
                  </h2>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry
                </p>
              </div>
            </div>
            <div className="col-md-6 content_text_cont">
              <div className="contcat-tex">
                <h3>Contact us</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="countact-dels">
                      <div className="call-col">
                        <span>
                          We 're interested in
                          <br /> working together{" "}
                        </span>
                        <a href="tel:+243 123 456 7890">+243 123 456 7890</a>
                        <span>
                          Visit our Office
                          <br /> around the world.
                        </span>
                        <strong>
                          1601 E 84th Ave, Anchorage,
                          <br />
                          Alabama, 99507
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="countact-dels">
                      <div className="call-col">
                        <span>
                          Have a project in mind? <br />
                          Send a message.
                        </span>
                        <a href="mailto:troothemes@email.com">
                          troothemes@email.com
                        </a>
                        <span>
                          Would you like to join
                          <br />
                          our growing team?
                        </span>
                        <strong>join.trootheme@email.com</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="troo-da-comments-section" id="troo-da-comments-section">
        <iframe
          src={googleMap}
          title="googlemap"
          width="100%"
          height="385"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="commet-text">
                <section className="troo-da-form-commet-section">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-section">
                        <form className="row">
                          <div className="col-md-6">
                            <input
                              type="FullName"
                              className="form-control"
                              id="inputFullName"
                              placeholder="Full Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="EmailAddress"
                              className="form-control"
                              id="inputEmailAddress"
                              placeholder="Email Address"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="Phone"
                              className="form-control"
                              id="inputPhone"
                              placeholder="Phone No"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="EmailSubject"
                              className="form-control"
                              id="inputSubject"
                              placeholder="Subject"
                            />
                          </div>
                          <div className="col-md-12">
                            <textarea> Write a message.....</textarea>
                          </div>
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary">
                              Submit form
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactComponent;
