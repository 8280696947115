import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import "./ServiceListComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import { ServiceData } from "./ServiceData";

const ServiceListComponent = () => {
  return (
    <div className="troo-da-services-section2" id="troo-da-services-section2">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                Our Services
              </div>
              <div className="title">
                <h2>
                  <span>
                    We always try to understand <br />
                  </span>
                  users expectation.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ser-tt">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
            </div>
          </div>
        </div>
        {ServiceData.map((e, i) => (
          <div className="row  service-col">
            <div className="col-md-6">
              <div className="service-img financ">
                <img src={e.img} alt="service1" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="services-colum">
                <span>{e.count} </span>
                <h3>{e.name} </h3>
                <p>{e.para2}</p>
                <div className="get-col">
                  <Link to={`/Home/Services/Service_Details?${createSearchParams({id : e.id}) }`}>Read More</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceListComponent;
