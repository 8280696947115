import React from "react";
import "./TeamComponent.css";
import { Link } from "react-router-dom";
import sub_text_icon from "../../Images/gre-aa.png";
import { teamdata } from "./TeamData";

const TeamCompoent = () => {
  console.log(teamdata);
  return (
    <>
      <div className="troo-da-services-section" id="troo-da-services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sub-title">
                <div className="text-sub">
                  <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                  Dedicated team
                </div>
                <div className="title">
                  <h2>
                    <span>
                      Expanding your unit by adding <br />
                      creative
                    </span>
                    ideas.
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ser-tt">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="troo-da-expert-section" id="troo-da-expert-section">
        <div className="container">
          <div className="row">
            {teamdata.map((data, i) => (
              <div className="col-md-3" key={i}>
                <div className="team-member">
                  <div className="picture">
                    <img src={data.img} alt="export_1" />
                    <div className="mask">
                      <div className="team-member-icons">
                        <ul>
                          {data.social.map((e, i) => (
                            <li key={i}>
                              <Link to={e.path}>
                                <div className="s-icon">{e.icon}</div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="team-text">
                      <strong>{data.name}</strong>
                      <span>{data.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {teamdata.map((data, i) => (
              <div className="col-md-3" key={i}>
                <div className="team-member">
                  <div className="picture">
                    <img src={data.img} alt="export_1" />
                    <div className="mask">
                      <div className="team-member-icons">
                        <ul>
                          {data.social.map((e, i) => (
                            <li key={i}>
                              <Link to={e.path}>
                                <div className="s-icon">{e.icon}</div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="team-text">
                      <strong>{data.name}</strong>
                      <span>{data.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCompoent;
