
export const MenuData = [
  {
    title: "Home",
    path: "/Home",
  },
  {
    title: "About Us",
    path: "/Home/About_Us",
  },
  {
    title: "Service",
    path: "#",
    subNav: [
      {
        subTitle : "Services",
        heading: "Services We Offer !",
        path: "/Home/Services",
      },
      {
        subTitle : "Services Lists",
        heading: "Services We Offer !",
        path: "/Home/Services_Lists",
      },
      {
        subTitle: "Service Details",
        heading : "Financial Planning",
        path: "/Home/Services/Service_Details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "Case Studies",
        heading : "Our Case Study",
        path: "/Home/Case_Studies",
      },
      {
        subTitle: "Portfolio",
        heading : "See our Portfolio",
        path: "/Home/Portfolio",
      },
      {
        subTitle : "Our Team",
        heading: "Meet Our Team Members",
        path: "/Home/Our_Team",
      },
      {
        subTitle : "Clients Review",
        heading: "Clients Review",
        path: "/Home/Clients_Review",
      },
      {
        subTitle: "FAQ",
        heading : "Frequently Asked Questions",
        path: "/Home/FAQ",
      },
      {
        subTitle: "404 Page",
        heading : "Error 404",
        path: "/Home/404_Page",
      },
      {
        subTitle: "Comming Soon",
        heading : "Comming Soon",
        path: "/Home/Comming_Soon",
      }
    ],
  },
  {
    title: "Blogs",
    path: "#",
    subNav: [
      {
        subTitle : "Blogs",
        heading: "Latest Blogs",
        path: "/Home/Blogs",
      },
      {
        subTitle: "Blog Details",
        heading :"Our Blogs Details",
        path: "/Home/Blog_Details",
      },
    ],
  },
  {
    title: "Contact Us",
    heading : "Contact Us",
    path: "/Home/Contact_Us",
  },
  {
    title: "Get a quote",
    heading : "Get a Quote",
    path: "/Home/Quote",
  },
];
