import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import CaseStudy from "./Pages/Page/CaseStudy"
import Portfolio from "./Pages/Page/Portfolio";
import TeamMember from "./Pages/Page/TeamMember";
import ClientReview from "./Pages/Page/ClientReview";
import Faq from "./Pages/Page/Faq";
import Page404 from "./Pages/Page/Page404";
import LatestBlog from "./Pages/Blogs/LatestBlog";
import BlogDetails from "./Pages/Blogs/BlogDetails";
import Contact from "./Pages/Contact";
import Quote from "./Pages/Quote";
import CommingSoon from "./Pages/Page/CommingSoon";
import Service from "./Pages/Service/Service";
import ServiceList from "./Pages/Service/ServiceList";
import ServiceDetails from "./Pages/Service/ServiceDetails";







function App() {
 
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/> } />
        <Route path="/Home" element={<Home/> } />
        <Route path="/Home/About_Us" element={<About/>}/>
        
        <Route path="/Home/Services" element={<Service/> } />
        <Route path= "/Home/Services_Lists" element={<ServiceList/>} />
        <Route path= "/Home/Services/Service_Details" element={<ServiceDetails/>} />

       
        <Route path="/Home/Case_Studies" element={<CaseStudy/>} />
        <Route path="/Home/Portfolio" element={<Portfolio/>} />
        <Route path="/Home/Case_Study/:id" element={<Portfolio/>} />
        <Route path="/Home/Our_Team" element={<TeamMember/>} />
        <Route path="/Home/Clients_Review" element={<ClientReview/>} />
        <Route path="/Home/FAQ" element={<Faq/>} />
        <Route path="/Home/404_Page" element={<Page404/>} />
        <Route path="/Home/Comming_Soon" element={<CommingSoon/>} />

        <Route path="/Home/Blogs" element={<LatestBlog/>} />
        <Route path="/Home/Blog_Details" element={<BlogDetails/>}  />

        <Route path="/Home/Contact_Us" element ={<Contact/>} />
        <Route path="/Home/Quote" element={<Quote/> } />
       
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
