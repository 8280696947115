import React from "react";
import Footer from "../../Components/Footer/Footer";
import FormComponent from '../../Components/ContactComponent/FormComponent'
import Header from "../../Components/Header/Header";
import HeroCompoentGlobal from "../../Components/HeroComponent/HeroCompoentGlobal";
import PortfolioComponent from "../../Components/PortfolioComponent/PortfolioComponent";
import "../../Components/PortfolioComponent/PortfolioComponent.css"
import useDocumentTitle from "../../PageTitle";
import { useParams } from "react-router-dom";

const Portfolio = () => {

  const params= useParams()
  console.log(params);

  useDocumentTitle("TROO Consulting Portfolio")
  return (
    <div className="portfolio-pages">
      <Header />
      <HeroCompoentGlobal />
      <PortfolioComponent/>
      <FormComponent/>
      <Footer />
    </div>
  );
};

export default Portfolio;
