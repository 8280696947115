
import cast_study_img_1 from "../../Images/man-working.png"
import cast_study_img_2 from "../../Images/businessman.png"
import cast_study_img_3 from "../../Images/busy-career.png"
import cast_study_img_4 from "../../Images/mobile-hand.png"
import cast_study_img_5 from "../../Images/apple-table.png"
import cast_study_img_6 from "../../Images/work-hand.png"
import "./CaseStudyComponent.css"


export const CaseStudyData = [
    {
        id : 1,
        title : "Information Technology",
        img : cast_study_img_1,
        sub : "Start Up",
        className : "project-colum"
    },
    {
        id : 2,
        title : "Host Analytics",
        img : cast_study_img_2,
        sub : "Start Up",
        className : "project-colum"
    },
    {
        id : 3,
        title : " Rackspace",
        img : cast_study_img_3,
        sub : "Start Up",
        
        className : "project-colum"
    },
    {
        id : 4,
        title : "Mendix",
        img : cast_study_img_4,
        sub : "Start Up",
        className : "project-colum mobile-hd"
    },
    {
        id : 5,
        title : "Happy Planet",
        img : cast_study_img_5,
        sub : "Start Up",
        className : "project-colum"
    },

    {
        id : 6,
        title : "English Heritage",
        img : cast_study_img_6,
        sub : "Start Up",
        className : "project-colum work-hd"
    },
    
]