import React from 'react'
import Faqpage from '../../Components/FaqComponent/Faqpage'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import "../../Components/FaqComponent/FaqPage.css"
import FormComponent from '../../Components/ContactComponent/FormComponent'
import useDocumentTitle from '../../PageTitle'

const Faq = () => {
  useDocumentTitle("TROO Consulting FAQ")
  return (
    <div className="faq-page">
        <Header/>
        <HeroCompoentGlobal/>
        <Faqpage/>
        <FormComponent/>
        <Footer/>
    </div>
  )
}

export default Faq