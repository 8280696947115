import React from "react";
import "./UpCommingComponent.css"
import com_vector from "../../Images/com-vector.png";
import cc_vector from "../../Images/cc-vector.png";
import logo from "../../Images/Logo-c.png";

const UpCommingCompnent = () => {
  return (
    <div className="troo-da-coming-soon-section" id="troo-da-coming-soon-section">
      <div className="vid-fig">
        <img src={com_vector} alt="com_vector" />
      </div>
      <div className="pot">
        <img src={cc_vector} alt="cc_vector" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="coimg-text">
              <div className="coming-logo">
                <img src={logo} alt="logo" />
              </div>
              <h2 className="comming_text">Coming Soon</h2>
              <span>
                All Good Things Come to
                <br /> Those who Wait...
              </span>
              <section className="home-newsletter">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="single">
                        <h2>Get notified when we launch</h2>
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Your Email"
                          />
                          <span className="input-group-btn">
                            <button className="btn btn-theme" type="submit">
                              Submit
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpCommingCompnent;
