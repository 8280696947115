import export_1 from "../../Images/computer-work-man.png"
import export_2 from "../../Images/portrait.png"
import export_3 from "../../Images/professional-clothing.png"
import export_4 from "../../Images/business-team-executive.png"

import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialFacebook,TiSocialLinkedin } from "react-icons/ti";
import { TbBrandInstagram } from "react-icons/tb";


export const teamdata = [
    {
        name : "David Jonson",
        title : "Project Manager",
        img : export_1,
        social : [
            {
                icon : <TiSocialFacebook/>,
                path : ""
            },
            {
                icon : <TbBrandInstagram/>,
                path : "",
            },
            {
                icon : <TiSocialLinkedin/>,
                path : ""
            },
            {
                icon : <TiSocialTwitter/>,
                path : ""
            }
        ]
    },
    {
        name : "Alexa Martin",
        title : "Product Manager",
        img : export_2,
        social : [
            {
                icon : <TiSocialFacebook/>,
                path : ""
            },
            {
                icon : <TbBrandInstagram/>,
                path : "",
            },
            {
                icon : <TiSocialLinkedin/>,
                path : ""
            },
            {
                icon : <TiSocialTwitter/>,
                path : ""
            }
        ]
    },
    {
        name : "Nonathan Maxwell",
        title : "Finance Manager",
        img : export_3,
        social : [
            {
                icon : <TiSocialFacebook/>,
                path : ""
            },
            {
                icon : <TbBrandInstagram/>,
                path : "",
            },
            {
                icon : <TiSocialLinkedin/>,
                path : ""
            },
            {
                icon : <TiSocialTwitter/>,
                path : ""
            }
        ]
    },
    {
        name : "Lily Denialson",
        title : "Finance Adviser",
        img : export_4,
        social : [
            {
                icon : <TiSocialFacebook/>,
                path : ""
            },
            {
                icon : <TbBrandInstagram/>,
                path : "",
            },
            {
                icon : <TiSocialLinkedin/>,
                path : ""
            },
            {
                icon : <TiSocialTwitter/>,
                path : ""
            }
        ]
    },
]
