import React, { useState } from "react";
import "./HeroComponent.css";
import circle_one from "../../Images/cr.png";
import circle_two from "../../Images/cr-2.png";
import frame from "../../Images/Frame.png";
import arrow from "../../Images/arrow.png";
import triangle from "../../Images/triangle.png";
import { Link } from "react-router-dom";
import sun_img from "../../Images/sub-Image.png";
import { IoCloseOutline } from "react-icons/io5";

const HeroComponent = () => {
  const [modal, setModal] = useState(false);

  const openModal = () => {
    if (!modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    setModal(!modal);
  };

  return (
    <div className="troo-da-hero-section" id="troo-da-hero-section">
      <div className="cricle-one">
        <img src={circle_one} alt="circle-one" />
      </div>
      <div className="cricle-one">
        <img src={circle_two} alt="circle-two" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="text-col">
              <div className="up">
                <img src={frame} alt="frame" />
              </div>
              <div className="text">Grow up your company</div>
              <h1>
                Branding, Digital Marketing
                <span className="hero-sub-title"> and Business Solutions</span>
              </h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                <br />
                industry. Lorem Ipsum has been the industry dummy text of the
                <br />
                printing and typesetting industry. Lorem Ipsum has
              </p>
              <img className="arr-img" src={arrow} alt="arrow" />
              <div className="get-col">
                <Link to="/Home/Quote">get a quote</Link>
              </div>

              <button className="vide-btn" onClick={openModal}>
                <img src={triangle} alt="triangle" /> <span>Watch video</span>
                {modal ? (
                  <section className="modal__bg">
                    <div className="modal__align">
                      <div className="modal__content" modal={modal}>
                        <IoCloseOutline
                          className="modal__close"
                          arial-label="Close modal"
                          onClick={setModal}
                        />
                        <div className="modal__video-align">
                          <iframe
                            className="modal__video-style"
                            width="800"
                            height="500"
                            src="https://www.youtube.com/embed/pXxsZtcbKdw?start=96"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : null}
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="vert-move">
              <img src={sun_img} alt="sub-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
