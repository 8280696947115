import React from 'react'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import ServiceComponent from '../../Components/ServiceComponent/ServiceComponent'
import Footer from '../../Components/Footer/Footer'

const ServiceList = () => {
  return (
    <div>
        <Header/>
        <HeroCompoentGlobal/>
        <ServiceComponent/>
        <Footer/>
    </div>
  )
}

export default ServiceList