import React from 'react'
import BlockDetailsComponent from '../../Components/BlogComponent/BlogDetailsComponent'

import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import useDocumentTitle from '../../PageTitle'

const BlogDetails = () => {
  useDocumentTitle("TROO Consulting Blog Details")
  return (
    <div className='blog-detail-page'>
        <Header/>
        <HeroCompoentGlobal/>
        <BlockDetailsComponent/>
        <Footer/>
    </div>
  )
}

export default BlogDetails