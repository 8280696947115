import React from "react";
import "./ErrorPage404.css";
import zero from "../../Images/eorro-zero.png"
import ellips from "../../Images/small-ellipse.png"
import { Link } from "react-router-dom";

const Errorpage404 = () => {
  return (
    <div className="troo-da-error-section" id="troo-da-error-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-text">
              <div className="vert-move">
                <img src={zero} alt="zero" />
              </div>
              <div className="smal-cr zoom-in-zoom-out">
                <img src={ellips} alt="ellips" />
              </div>
              <h4>Error </h4>
              <h5>404</h5>
              <span>Page not found</span>
              <p>
                Oops! The page you are looking for does not exist. It might have
                been moved or deleted.
              </p>
              <div className="get-col">
                <Link to="/">go to home page</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Errorpage404;
