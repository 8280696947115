import React from "react";
import sub_text_icon from "../../Images/gre-aa.png";
import "./BlogLatestComponent.css";
import { blogData } from "./BlogData";
import { Link, createSearchParams } from "react-router-dom";

const BlogLatestComponent = () => {
  return (
    <>
      <div className="troo-da-blog-section" id="troo-da-blog-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sub-title">
                <div className="text-sub">
                  <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                  Latest Blogs and News
                </div>
                <div className="title">
                  <h2>
                    <span>
                      We have many reviews from
                      <br /> our satisfied
                    </span>
                    <span className="blog_title_highlight">clients.</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ser-tt">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="troo-da-blogs-section" id="troo-da-blogs-section">
        <div className="container">
          <div className="row">
            {blogData.slice(0, 3).map((data, i) => (
              <div className="col-md-4" key={i}>
                <Link
                  to={`/Home/Blog_Details?${createSearchParams({
                    id: data.id,
                  })}`}
                >
                  <div className="blog-content">
                    <div className="bl-img">
                      <img src={data.img} alt="blog_1" />
                      <div className="date-ss">{data.date}</div>
                    </div>
                    <div className="blog-text">
                      <h3>{data.title}</h3>
                      <div className="blog-client">
                        <div className="blog-img">
                          <img src={data.avtar} alt="blog_person_1" />
                          <strong> {data.desig}</strong>
                        </div>
                        <div className="blog-con">
                          <span>{data.work}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <div className="row">
            {blogData.slice(3, 6).map((data, i) => (
              <div className="col-md-4" key={i}>
                <Link
                  to={`/Home/Blog_Details?${createSearchParams({
                    id: data.id,
                  })}`}
                >
                  <div className="blog-content">
                    <div className="bl-img">
                      <img src={data.img} alt="blog_1" />
                      <div className="date-ss">{data.date}</div>
                    </div>
                    <div className="blog-text">
                      <h3>{data.title}</h3>
                      <div className="blog-client">
                        <div className="blog-img">
                          <img src={data.avtar} alt="blog_person_1" />
                          <strong> {data.desig}</strong>
                        </div>
                        <div className="blog-con">
                          <span>{data.work}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <div className="row">
            {blogData.slice(6, 9).map((data, i) => (
              <div className="col-md-4" key={i}>
                <Link
                  to={`/Home/Blog_Details?${createSearchParams({
                    id: data.id,
                  })}`}
                >
                  <div className="blog-content">
                    <div className="bl-img">
                      <img src={data.img} alt="blog_1" />
                      <div className="date-ss">{data.date}</div>
                    </div>
                    <div className="blog-text">
                      <h3>{data.title}</h3>
                      <div className="blog-client">
                        <div className="blog-img">
                          <img src={data.avtar} alt="blog_person_1" />
                          <strong> {data.desig}</strong>
                        </div>
                        <div className="blog-con">
                          <span>{data.work}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogLatestComponent;
