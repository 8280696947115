import React from 'react'
import BrandLogo from '../../Components/BrandLogo/BrandLogo'
import Footer from '../../Components/Footer/Footer'
import FormComponent from '../../Components/ContactComponent/FormComponent'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import Reviewpage from '../../Components/TestimonialComponent/Reviewpage'
import "../../Components/TestimonialComponent/ReviewPage.css"
import useDocumentTitle from '../../PageTitle'

const ClientReview = () => {
  useDocumentTitle("TROO Consulting Client Review")
  return (
    <div className="client-review">
        <Header/>
        <HeroCompoentGlobal/>
        <Reviewpage/>
        <BrandLogo/>
        <FormComponent/>
        <Footer/>
    </div>
  )
}

export default ClientReview