import React from "react";
import "./ServiceComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import { Link,createSearchParams } from "react-router-dom";
import { ServiceData } from "./ServiceData";

const ServiceComponent = () => {
  return (
    <div className="troo-da-services-section" id="troo-da-services-section">
      <div className="container">
        <div className="row service_cards">
          <div className="col-md-12">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                Our Services
              </div>
              <div className="title">
                <h2>
                  <span>
                    We always try to understand <br />
                  </span>
                  users expectation.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {ServiceData.slice(0, 3).map((e, i) => (
              <div key={i} className="col-md-4">
                <div className="services-colum">
                  <span>{e.count}</span>
                  <h3>{e.name} </h3>
                  <p>{e.para}</p>
                  <div className="get-col">
                    <Link to={`/Home/Services/Service_Details?${createSearchParams({id : e.id}) }`}>Read More</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {ServiceData.slice(3, 6).map((e, i) => (
              <div key={i} className="col-md-4">
                <div className="services-colum">
                  <span>{e.count}</span>
                  <h3>{e.name} </h3>
                  <p>{e.para}</p>
                  <div className="get-col">
                    <Link to={`/Home/Services/Service_Details?${createSearchParams({id : e.id}) }`}>Read More</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-12">
            <div className="get-col service-btn">
              <Link to="/Home/Services">View All Services</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;
