import React from "react";
import "./ExportsComponent.css";
import cross from "../../Images/crose.png"
import orange_arrow from "../../Images/org-arrow.png"
import sub_text_icon from "../../Images/gre-aa.png"
import { Link } from "react-router-dom";
import { teamdata } from "./TeamData";



const ExportsComponent = () => {
  return (
    <div className="troo-da-expert-section" id="troo-da-expert-section">
      <div className="spinner-wrap">
        <div className="spinner-item"></div>
        <div className="spinner-item spinner-item--2"></div>
        <div className="spinner-item spinner-item--3"></div>
      </div>
      <div className="zoom-in-zoom-out">
        <img src={cross} alt="cross_icon" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                Our Expert
              </div>
              <div className="title">
                <h2>
                  <span>
                    Meet our experienced team
                    <br />
                  </span>
                  member.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
          {teamdata.map((data, i) => (
            <div className="col-md-3" key={i}>
              <div className="team-member">
                  <div className="picture" >
                    <img src={data.img} alt="export_1" />
                    <div className="mask">
                      <div className="team-member-icons">
                        <ul>
                          {data.social.map((e, i) => (
                            <li key={i}>
                              <Link to={e.path} >
                               <div className="s-icon">{e.icon}</div>
                                </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <strong>{data.name}</strong>
                    <span>{data.title}</span>
                  </div>
              </div>
            </div>
            ))}
            <div className="col-md-12">
              <div className="case-btn">
                <div className="get-col">
                  <Link to="/Home/Our_Team">View All Member</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vert-move">
        <img src={orange_arrow} alt="orange_arrow" />
      </div>
    </div>
  );
};

export default ExportsComponent;
