import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import HeroCompoentGlobal from '../Components/HeroComponent/HeroCompoentGlobal'
import QuoteComponent from '../Components/QuoteComponent/QuoteComponent'
import "../Components/QuoteComponent/QuoteComponent.css"
import useDocumentTitle from '../PageTitle'
const Quote = () => {
  useDocumentTitle("TROO Consulting Quote")
  return (
    <div className="get-quote-page">
        <Header/>
        <HeroCompoentGlobal/>
        <QuoteComponent/>
        <Footer/>
    </div>
  )
}

export default Quote