import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import HeroCompoentGlobal from "../Components/HeroComponent/HeroCompoentGlobal";
import ContactComponent from "../Components/ContactComponent/ContactComponent";
import "../Components/ContactComponent/ContactComponent.css"
import useDocumentTitle from "../PageTitle";
const Contact = () => {
  useDocumentTitle("TROO Consulting Contact")
  return (
    <div className="contact-page">
      <Header />
      <HeroCompoentGlobal/>
      <ContactComponent/>
      <Footer />
    </div>
  );
};

export default Contact;
