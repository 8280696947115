import React from 'react'
import sub_text_icon from "../../Images/gre-aa.png";
import Accordion from "react-bootstrap/Accordion";
import "./FaqPage.css"

const Faqpage = () => {
  return (
    <>
    <div className="troo-da-services-section" id="troo-da-services-section">
    <div className="container">
      <div className="row">
       <div className="col-md-6">
           <div className="sub-title">
               <div className="text-sub"><img  className="up" src={sub_text_icon} alt='sub_text_icon' />Find the Right Answer</div>
               <div className="title"><h2><span> You can learn more from our<br/> asked</span> questions.</h2></div>
              
           </div> 
       </div>
       <div className="col-md-6">
          <div className="ser-tt">
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
             Lorem Ipsum has been the industry's standard.</p>
          </div>
       </div>
      </div>
      </div>
    </div>
    <div className="faq" id="faq">
    <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Why do I need a business consulting service?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How much does it cost to hire a business consultant?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What industries or businesses do you specialize in?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How do you price your services?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the terms of working with a business consultant?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What are the terms of working with a business consultant?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  What are the terms of working with a business consultant?
                </Accordion.Header>
                <Accordion.Body>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting. Lorem Ipsum has been the industry's standard
                  dummy text ever since.simply dummy text of the printing and
                  typesetting.
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </div>
        </div>
      </div>  
    </div>
    </>
  )
}

export default Faqpage