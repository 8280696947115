import React from "react";
import "./BlogDetailsComponent.css";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { TiSocialTwitter } from "react-icons/ti";
import { Link } from "react-router-dom";
import sub_text_icon from "../../Images/gre-aa.png";
import { blogData } from "./BlogData";
import blogDetailsImg from "../../Images/de-image.png"

const BlockDetailsComponent = () => {
  return (
    <>
      <div className="troo-da-blog-detail-section" id="troo-da-detail-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="details-text">
                <span>Business - Marketing / 22 Aug, 2022 - By Admin</span>
                <h2>New Birmingham office for Accenture</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took.
                </p>
                <div className="deails-blog">
                  <img src={blogDetailsImg} alt="blogDetailsImg" />
                </div>
                <h3>What is Lorem Ipsum?</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like.
                </p>
                <div className="de-contetn">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley
                </div>
                <h3>Where does it come from?</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with.
                </p>
                <div className="socail-con">
                  <div className="socal-icon">
                    <span>Share on:</span>
                    <ul>
                      <li>
                        <Link to="#">
                          <FaFacebookF className="i" />
                          <span>Facebook</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <FaInstagram className="i" />
                          <span>Instagram</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <FaLinkedinIn className="i" />
                          <span>Linkedin</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <TiSocialTwitter className="i" />
                          <span>Twitter</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="troo-da-comments-section" id="troo-da-comments-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="commet-text">
                <h2>0 Comments</h2>
                <span>Submit a Comment</span>
                <section className="troo-da-form-commet-section">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-section">
                        <form className="row">
                          <div className="col-md-12">
                            <input
                              type="FullName"
                              className="form-control"
                              id="inputFullName"
                              placeholder="Full Name"
                            />
                          </div>
                          <div className="col-md-12">
                            <input
                              type="EmailAddress"
                              className="form-control"
                              id="inputEmailAddress"
                              placeholder="Email Address"
                            />
                          </div>
                          <div className="col-md-12">
                            <input
                              type="EmailWebsite"
                              className="form-control"
                              id="inputWebsite"
                              placeholder="Website"
                            />
                          </div>
                          <div className="col-md-12">
                            <textarea> Write a message.....</textarea>
                          </div>
                          <div className="col-12">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                          <p>
                            save my name, email, and website in this browser for
                            the next time i comment.
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="troo-da-blogs-section" id="troo-da-blogs-section">
      <div className="container">
    <div className="row">
         <div className="col-md-12">
          <div className="sub-title">
            <div className="text-sub"><img  className="up" src={sub_text_icon} alt="sub_text_icon" />Related posts</div>
            <div className="title"><h2><span>View the Latest News in <br/></span> Consultancy.</h2></div>
          </div>
      </div>
    </div>
     <div className="row">
     {blogData.map((data, i) => (
              <div className="col-md-4" key={i}>
                <div className="blog-content">
                  <div className="bl-img">
                    <img src={data.img} alt="blog_1" />
                    <div className="date-ss">{data.date}</div>
                  </div>
                  <div className="blog-text">
                    <h3>{data.title}</h3>
                    <div className="blog-client">
                      <div className="blog-img">
                        <img src={data.avtar} alt="blog_person_1" />
                        <strong> {data.desig}</strong>
                      </div>
                      <div className="blog-con">
                        <span>{data.work}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
    
     </div>
</div>
      </div>
    </>
  );
};

export default BlockDetailsComponent;
