import React from "react";
import "./CaseStudyComponent.css";
import { Link, createSearchParams } from "react-router-dom";
import sub_text_icon from "../../Images/gre-aa.png";
import { CaseStudyData } from "./CaseStudyData";

const CaseStudyComponent = () => {
 
  return (
    <div
      className="troo-da-case-studies-section"
      id="troo-da-case-studies-section"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                Case Studies
              </div>
              <div className="title">
                <h2>
                  <span>Our best </span>projects.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="case-text">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="case-btn">
              <div className="get-col">
                <Link to="/Home/Case_Studies">View All Project</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {CaseStudyData.slice(0, 3).map((item, i) => (
            <div className="col-md-4" key={i} >
             <Link to={`/Home/Portfolio?${createSearchParams({id:item.id})}`}>
             <div className="project-colum">
                <img src={item.img} alt="case_study_img_1" />
                <div className="team-deatils">
                  <p>{item.sub}</p>
                  <span>{item.title}</span>
                </div>
              </div>
             </Link>
            </div>
          ))}
         
        </div>
        <div className="row">
          {CaseStudyData.slice(3, 6).map((item, i) => (
            <div className="col-md-4" key={i} >
             <Link to={`/Home/Portfolio?${createSearchParams({id:item.id})}`}>
             <div className={item.className}>
                <img src={item.img} alt={item.title} />
                <div className="team-deatils">
                  <p>{item.sub}</p>
                  <span>{item.title}</span>
                </div>
              </div>
             </Link>
            </div>
          ))}
          
        </div>
      </div>
    </div>
  );
};

export default CaseStudyComponent;
