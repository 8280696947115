import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import "../../Components/ErrorPage/ErrorPage404.css"
import Errorpage404 from '../../Components/ErrorPage/Errorpage404'
import useDocumentTitle from '../../PageTitle'

const Page404 = () => {
  useDocumentTitle("TROO Consulting 404 Page")
  return (
    <div className="error-page">
        <Header/>
        <HeroCompoentGlobal/>
        <Errorpage404/>
        <Footer/>
    </div>
  )
}

export default Page404