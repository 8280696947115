import cr from "../../Images/cr.png";
import cr_2 from "../../Images/cr-2.png";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import "./HeroComponentGlobal.css";
import { useEffect, useState } from "react";
import { MenuData } from "../Header/MenuData";
import { ServiceData } from "../ServiceComponent/ServiceData";
import { CaseStudyData } from "../CaseStuduyComponent/CaseStudyData";
import { blogData } from "../BlogComponent/BlogData";

const HeroCompoentGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;


  useEffect(() => {
    const id = searchParams.get("id");
    if(id){
      if(pathName.includes("Service_Details")){
        const sData = ServiceData.find((d)=>d.id == id)
        setHeading(sData.name)
      }
      if(pathName.includes("Portfolio")){
        const pData = CaseStudyData.find((d)=>d.id == id)
        setHeading(pData.title)
      }
      if(pathName.includes("Blog_Details")){
        const bData = blogData.find((d)=>d.id == id)
        setHeading(bData.title)
      }
    }
    else{
      const data = MenuData.find((d) => {
        if (d?.subNav) {
          const subTitle = d?.subNav?.find((s) => s.path === pathName);
          if (subTitle) {
            return d;
          }
        } else if (d.path === pathName) {
          return d;
        }
      });
  
      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.title);
      }
    }
  }, []);

  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);

  return (
    <div className="troo-da-sub-hero-section" id="troo-da-sub-hero-section">
      <div className="cricle-one">
        <img src={cr} alt="cr" />
      </div>
      <div className="cricle-one">
        <img src={cr_2} alt="cr-2" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-page-title">
              {/* {!subTitle ? (
                <h2>{title.replace(/_/g, " ")}</h2>
              ) : (
                <h2>{subTitle.replace(/_/g, " ")}</h2>
              )} */}
              <h2>{heading}</h2>
              <ul className="breadcrumb">
                {breadcrumbs.map((breadcrumb, index) => (
                  <li
                    className={`breadcrumb-item${
                      index === breadcrumbs.length - 1 ? " current" : ""
                    }`}
                  >
                    <Link key={index} to={breadcrumb.path}>
                      {breadcrumb.title.replace(/_/g, " ")}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeroCompoentGlobal;
