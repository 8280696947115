import React, { useState } from "react";
import "./FormComponent.css";
import line_one from "../../Images/line-one.png";
import sub_text_icon from "../../Images/gre-aa.png";
import cross from "../../Images/crose.png";

const FormComponent = () => {
  const [text, setText] = useState('');

  function handleChange(event) {
    setText(event.target.value);
  }
  
  return (
    <div className="troo-da-form-section" id="troo-da-form-section">
      <div className="car-line">
        <img className="ineer1" src={line_one} alt="line_one" />
      </div>
      <div className="galaxy">
        <div className="mercury"></div>
        <div className="orbit-mercury"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_text_icon" />
                Grow your business with us
              </div>
              <div className="title">
                <h2>
                  <span> We are Here to Help You & Your </span> Business.
                </h2>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry
              </p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="form-section">
              <form className="row">
                <div className="col-md-6">
                  <input
                    type="FullName"
                    className="form-control"
                    id="inputFullName"
                    placeholder="Full Name"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="EmailAddress"
                    className="form-control"
                    id="inputEmailAddress"
                    placeholder="Email Address"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="Phone"
                    className="form-control"
                    id="inputPhone"
                    placeholder="Phone No"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="EmailSubject"
                    className="form-control"
                    id="inputSubject"
                    placeholder="Subject"
                  />
                </div>
                <div className="col-md-12">
                  <textarea value={text} onChange={handleChange} placeholder="Write a message....." /> 
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary">
                    Submit form
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="zoom-in-zoom-out">
          <img src={cross} alt="cross_icon" />
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
