import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import UpCommingCompnent from '../../Components/UpCommingComponent/UpCommingCompnent'
import useDocumentTitle from '../../PageTitle'

const CommingSoon = () => {
  useDocumentTitle("TROO Consulting Comming Soon")
  return (
    <div>
        <Header/>
        <HeroCompoentGlobal/>
        <UpCommingCompnent/>
        <Footer/>
    </div>
  )
}

export default CommingSoon