import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import "../../Components/ExporstComponent/TeamComponent.css"
import TeamCompoent from '../../Components/ExporstComponent/TeamCompoent'
import useDocumentTitle from '../../PageTitle'

const TeamMember = () => {
  useDocumentTitle("TROO Consulting Team Members")
  return (
    <div className="meet-team-page">
        <Header/>
        <HeroCompoentGlobal/>
        <TeamCompoent/>
        <Footer/>
    </div>
  )
}

export default TeamMember