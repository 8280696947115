import React from 'react'
import BlogLatestComponent from '../../Components/BlogComponent/BlogLatestComponent'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import HeroCompoentGlobal from '../../Components/HeroComponent/HeroCompoentGlobal'
import "../../Components/BlogComponent/BlogLatestComponent.css"
import FormComponent from '../../Components/ContactComponent/FormComponent'
import useDocumentTitle from '../../PageTitle'

const LatestBlog = () => {
  useDocumentTitle("TROO Consulting Latest Blogs")
  return (
    <div className="blog-page">
        <Header/>
        <HeroCompoentGlobal/>
        <BlogLatestComponent/>
        <FormComponent/>
        <Footer/>
    </div>
  )
}

export default LatestBlog