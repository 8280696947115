import React, { useState } from "react";
import "./CounterComponent.css";
import sub_text_icon from "../../Images/gre-aa.png";
import smil_normal from "../../Images/smaile-Icon-blu.png";
import smil_hover from "../../Images/smaile-Icon.png";
import file_normal from "../../Images/file-Icon.png";
import file_hover from "../../Images/file-Icon-org.png";
import thumb_normal from "../../Images/tamb-Icon.png";
import thumb_hover from "../../Images/tamb-Icon-org.png";
import award_normal from "../../Images/award-Icon.png";
import award_hover from "../../Images/award-Icon-org.png";

import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterComponent = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="troo-da-counter-section" id="troo-da-counter-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="sub-title">
              <div className="text-sub">
                <img className="up" src={sub_text_icon} alt="sub_icon" />
                Our Progress
              </div>
              <div className="title">
                <h2>
                  <span>
                    Doing the right thing, at the
                    <br />
                  </span>
                  right time.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <div className="counter-box">
                  <div className="img-conter">
                    <img
                      className="normal-img"
                      src={smil_normal}
                      alt="smile_normal"
                    />
                    <img className="hover-img" src={smil_hover} alt="smile_hover" />
                  </div>
                  <div className="con-text">

                  <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                      className="con-textsss"
                    >
                        {counter && (
                          <CountUp
                            start={0}
                            end={25}
                            duration={2}
                            delay={0}
                            className="counter"
                          />
                        )}<strong>K +</strong>
                    </ScrollTrigger>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="counter-box">
                  <div className="img-conter">
                    <img
                      className="normal-img"
                      src={file_normal}
                      alt="file_normal"
                    />
                    <img className="hover-img" src={file_hover} alt="file_hover" />
                  </div>
                  <div className="con-text">
                    <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                    >
                        {counter && (
                          <CountUp
                            start={250}
                            end={366}
                            duration={2}
                            delay={0}
                            className="counter"
                          />
                        )} <strong>+</strong>
                    </ScrollTrigger>
                    <p>Project Done</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="counter-box">
                  <div className="img-conter">
                    <img
                      className="normal-img"
                      src={thumb_normal}
                      alt="thumb_normal"
                    />
                    <img
                      className="hover-img"
                      src={thumb_hover}
                      alt="thumb_hover"
                    />
                  </div>
                  <div className="con-text">

                  <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                    >
                        {counter && (
                          <CountUp
                            start={50}
                            end={152}
                            duration={2}
                            delay={0}
                            className="counter"
                          />
                        )}<strong>+</strong>
                    </ScrollTrigger>

                    <p>Business Partner</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="counter-box">
                  <div className="img-conter">
                    <img
                      className="normal-img"
                      src={award_normal}
                      alt="award_normal"
                    />
                    <img
                      className="hover-img"
                      src={award_hover}
                      alt="award_hover"
                    />
                  </div>
                  <div className="con-text">

                  <ScrollTrigger
                      onEnter={() => setCounter(true)}
                      onExit={() => setCounter(false)}
                    >

                        {counter && (
                          <CountUp
                            start={0}
                            end={82}
                            duration={2}
                            delay={0}
                            className="counter"
                          />
                        )}<strong>+</strong>
                    </ScrollTrigger>

                    <p>Awards Winning</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterComponent;
