import blog_1 from "../../Images/blog-1.png";
import blog_2 from "../../Images/blog-2.png";
import blog_3 from "../../Images/blog-3.png";
import blog_person_1 from "../../Images/blog-persone-1.png";
import blog_person_2 from "../../Images/blog-persone-2.png";

export const blogData = [
  {
    id: 1,
    title: "New Birmingham office for Accenture",
    date: "08 Aug 2022",
    img: blog_1,
    desig: "By Admin",
    avtar: blog_person_1,
    work: "Investment",
  },
  {
    id: 2,
    title: "Cloud acquires Microsoft data specialist BlueGranite",
    date: "07 Aug 2022",
    img: blog_2,
    desig: "By Admin",
    avtar: blog_person_2,
    work: "Marketing",
  },
  {
    id: 3,
    title: "Flexible working: The rights of employers and employees",
    date: "06 Aug 2022",
    img: blog_3,
    desig: "By Admin",
    avtar: blog_person_1,
    work: "Sales",
  },{
    id: 4,
    title: "New Birmingham office for Accenture",
    date: "08 Aug 2022",
    img: blog_1,
    desig: "By Admin",
    avtar: blog_person_1,
    work: "Investment",
  },
  {
    id: 5,
    title: "Cloud acquires Microsoft data specialist BlueGranite",
    date: "07 Aug 2022",
    img: blog_2,
    desig: "By Admin",
    avtar: blog_person_2,
    work: "Marketing",
  },
  {
    id: 6,
    title: "Flexible working: The rights of employers and employees",
    date: "06 Aug 2022",
    img: blog_3,
    desig: "By Admin",
    avtar: blog_person_1,
    work: "Sales",
  },
  {
    id: 7,
    title: "New Birmingham office for Accenture",
    date: "08 Aug 2022",
    img: blog_1,
    desig: "By Admin",
    avtar: blog_person_1,
    work: "Investment",
  },
  {
    id: 8,
    title: "Cloud acquires Microsoft data specialist BlueGranite",
    date: "07 Aug 2022",
    img: blog_2,
    desig: "By Admin",
    avtar: blog_person_2,
    work: "Marketing",
  },
  {
    id: 9,
    title: "Flexible working: The rights of employers and employees",
    date: "06 Aug 2022",
    img: blog_3,
    desig: "By Admin",
    avtar: blog_person_1,
    work: "Sales",
  },
];
